import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";

const cashbackColor = "#49b9d0";
const colors = [
  "#ffbc10",
  "#FF9D00",
  "#f28928",
  "#db7800",
  "#FFAE00",
  "#FFA800",
  "#FF8C00",
];

let cachedData = [];
let imageSeries = null;
const minDistanceForNotMergable = 13;
/* const maxCircleRadius = 15;
const addPixelRadiusPerBar = 3; */
const levels = [1, 2, 5, 10, 15];
let dataForLevels = {};

for (const level of levels) {
  dataForLevels = { ...dataForLevels, [level]: null };
}

export const mapController = {
  async addRegionPins(map, chart, bars, regionName) {
    /* TODO ADD RESET PINS */

    if (bars[0].region === regionName) {
      let imageSeries = chart.series.push(
        am5map.MapPointSeries.new(map, {
          latitudeField: "latitude",
          longitudeField: "longitude",
        })
      );
      this.defineBullets(map, imageSeries, true);

      let data = await this.calculateImageSeriesData(bars, true);
      imageSeries.data.setAll(data);
      console.log("imageSeries ------", imageSeries);
    }
  },
  //TODO IMPLEMENTA UM REMOVE PINS, CHEIRA'ME QUE VAI SER PRECISO
  //TODO IMPLEMENTA UM CLEAR PINS, CHEIRA'ME QUE VAI SER PRECISO
  async addPins(map, chart, bars, init = false, first) {
    if (init) {
      imageSeries = chart.series.push(
        am5map.MapPointSeries.new(map, {
          latitudeField: "latitude",
          longitudeField: "longitude",
          id: "series",
        })
      );

      imageSeries.events.on("datavalidated", (e) => {
        console.log("datavalidated");
      });

      imageSeries.events.on("geodataprocessed", (e) => {
        console.log("geodataprocessed");
      });

      let data = await this.calculateImageSeriesData(bars);
      // await imageSeries.hide();

      // here
      await this.calculateImageSeriesForLevels(chart, imageSeries, data);
      //chart.show();

      this.defineBullets(map, imageSeries);
      chart.goHome(0);
      // await imageSeries.show();
    } else {
      imageSeries.bulletsContainer.children.clear();
      imageSeries.bullets.clear();
      this.defineBullets(map, imageSeries);
      await imageSeries.show();
    }
  },

  getZoomIndex(level) {
    for (let i = 0; i < levels.length; i++) {
      let levelExistent = levels[i];
      if (level >= levelExistent && level < levels[i + 1]) return levelExistent;
    }
    return levels[levels.length - 1];
  },

  hideMapBullets(animation = 700) {
    if (imageSeries) {
      imageSeries.hide(animation);
    }
  },
  displayMapBullets() {
    if (imageSeries) {
      imageSeries.show(0);
    }
  },

  async calculateImageSeriesForLevels(chart, imageSeries, data) {
    for (const level of levels) {
      await chart.zoomToGeoPoint(
        { longitude: 0, latitude: 0 },
        level,
        undefined,
        0
      );

      await new Promise((resolve) => setTimeout(resolve, 10));

      let dataForLevel;
      if (!dataForLevels[level]) {
        dataForLevel = this.mergeLocations(data, chart, level);
        dataForLevels[level] = dataForLevel;
      } else {
        console.log("using cached dataForLevels");
        dataForLevel = dataForLevels[level];
      }
      // TODO always returns the same because cant zoom to level
      console.log("merged location length", dataForLevel);

      // TODO can join if same point exists in two concecutive levels, put just on point with max zoomLevelToHide
      for (const point of dataForLevel) {
        imageSeries.data.push(point);
      }
    }
  },

  showMapBullets(map, chart, bars) {
    this.addPins(map, chart, bars);
  },

  isBetweenZoom(zoomLevel, dataItem) {
    return (
      (zoomLevel >= dataItem.dataContext.zoomLevelToShow &&
        zoomLevel < dataItem.dataContext.zoomLevelToHide) ||
      (dataItem.dataContext.zoomLevelToShow === levels[levels.length - 1] &&
        zoomLevel == levels[levels.length - 1])
    );
  },

  defineBullets(map, imageSeries, isRegion = false) {
    //add zoom level to function bullets

    imageSeries.bullets.push((_, series, dataItem) => {
      if (
        this.isBetweenZoom(series.chart._settings.zoomLevel, dataItem) ||
        isRegion
      ) {
        let circle = null;

        if (!dataItem.dataContext.bar.favicon) {
          circle = am5.Circle.new(map, {
            radius: 5,
            templateField: "bulletSettings",
          });

          circle.animate({
            key: "opacity",
            from: 0.6,
            to: 0,
            loops: Infinity,
            duration: mapController.getRandomIntInclusive(1000, 2000),
            easing: am5.ease.yoyo(am5.ease.quad),
          });

          circle.animate({
            key: "radius",
            to: 8,
            loops: Infinity,
            duration: 2000,
            easing: am5.ease.yoyo(am5.ease.quad),
          });
        } else {
          circle = am5.Picture.new(map, {
            width: 16,
            height: 16,
            centerX: am5.percent(50),
            centerY: am5.percent(50),
            src: dataItem.dataContext.bar.favicon,
          });

          circle.animate({
            key: "width",
            to: 25,
            loops: Infinity,
            duration: 2000,
            easing: am5.ease.yoyo(am5.ease.quad),
          });

          circle.animate({
            key: "height",
            to: 25,
            loops: Infinity,
            duration: 2000,
            easing: am5.ease.yoyo(am5.ease.quad),
          });
        }

        // TODO proof of concept for hover; delete it if not needed
        // circle.states.create("hover", {
        //   width: 100,
        //   height: 100,
        //   interactive: true,
        // });

        return am5.Bullet.new(map, {
          sprite: circle,
        });
      }
    });

    //check if this is doing anything
    imageSeries.bullets.push((_, series, dataItem) => {
      if (!dataItem.dataContext.bar.favicon) {
        if (
          this.isBetweenZoom(series.chart._settings.zoomLevel, dataItem) ||
          isRegion
        ) {
          let circle = am5.Circle.new(map, {
            templateField: "bulletSettings",
          });

          return am5.Bullet.new(map, {
            sprite: circle,
          });
        }
      } else {
        return null;
      }
    });

    imageSeries.bullets.push((_, series, dataItem) => {
      if (
        this.isBetweenZoom(series.chart._settings.zoomLevel, dataItem) ||
        isRegion
      ) {
        return am5.Bullet.new(map, {
          locationX: 0.5,
          locationY: 0.5,
          sprite: am5.Label.new(map, {
            text: "{quantity}",
            fill: am5.color("#454545"),
            populateText: true,
            centerX: am5.p50,
            centerY: am5.p50,
            textAlign: "center",
            fontSize: 9,
            fontWeight: "bold",
            layer: 1,
          }),
        });
      }
    });

    /*     imageSeries.bullets.push(function () {
      const picture = am5.Picture.new(map, {
        templateField: "bulletSettings",
        width: 10,
        height: 10,
        centerX: am5.p50,
        centerY: am5.p50,
        radius: 5,
      });

      return am5.Bullet.new(map, {
        sprite: picture,
      });
    }); */
  },

  mergeBulletColor(mergedPointAmount) {
    if (mergedPointAmount == 1) {
      return colors[0];
    }

    if (mergedPointAmount == 2) {
      return colors[1];
    }
    if (mergedPointAmount >= 3 && mergedPointAmount < 6) {
      return colors[2];
    }
    if (mergedPointAmount >= 6 && mergedPointAmount < 10) {
      return colors[3];
    }

    return colors[4];
  },

  async calculateImageSeriesData(bars, isRegion = false) {
    if (
      cachedData.length == 0 ||
      bars.length !== cachedData.length ||
      isRegion
    ) {
      let promises = [];
      for (const bar of bars) {
        promises.push(
          new Promise((resolve) => {
            // your bullet color is configured here
            let bulletColor;

            if (bar.cashback > 0) {
              bulletColor = am5.color(cashbackColor);
            } else if (bar.iconColor) {
              bulletColor = bar.iconColor;
            } else {
              bulletColor = am5.color(colors[0]);
            }

            // const bulletColor = bar.iconColor
            //   ? bar.iconColor
            //   : am5.color(colors[0]);

            // bar.cashback > 0
            //   ? am5.color(cashbackColor)
            //   : am5.color(colors[0]);

            resolve({
              id: bar.id,
              latitude: bar.latitude,
              longitude: bar.longitude,
              name: bar.name,
              //jackpot: this.$options.filters.money(bar.jackpot),
              jackpot: bar.jackpot, // this needs work as above
              address: bar.address,
              fill: bulletColor,
              bar: bar,
              // backgroundColor: bar.iconColor
              //   ? bar.iconColor
              //   : am5.color(colors[0]),
              backgroundColor: bulletColor,
              // THIS WORKS!
              bulletSettings: {
                src: bar.favicon ? bar.favicon : null,
                radius: 2,
                fill: bulletColor,
              },
            });
          })
        );
      }
      cachedData = await Promise.all(promises);
    }

    return cachedData;
  },

  getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },

  arePointsOverlapping(location1, location2, chart) {
    const locationPoint1 = chart.convert({
      latitude: location1.latitude,
      longitude: location1.longitude,
    });

    const locationPoint2 = chart.convert({
      latitude: location2.latitude,
      longitude: location2.longitude,
    });

    const xDiff = Math.abs(locationPoint1.x - locationPoint2.x);
    const yDiff = Math.abs(locationPoint1.y - locationPoint2.y);
    const distance = Math.hypot(xDiff, yDiff);
    return distance < minDistanceForNotMergable;
  },

  getMinAndMaxZoom(zoom) {
    for (let i = 0; i < levels.length; i++) {
      let currentLevel = levels[i];
      let nextLevel = i === levels.length - 1 ? levels[i] : levels[i + 1];

      if (zoom >= currentLevel && zoom < nextLevel) {
        return { zoomLevelToShow: currentLevel, zoomLevelToHide: nextLevel };
      }
    }
    //EVERYTHING IS LANDING HERE, HELP,
    return {
      zoomLevelToShow: levels[levels.length - 1],
      zoomLevelToHide: levels[levels.length - 1],
    };
  },

  mergeLocations(locations, chart, zoomLevel) {
    const mergedLocations = [];
    const locationsThatExistInAMerge = [];

    const zoomLevel1 = chart._settings.zoomLevel;
    console.log("zoomLevel no merge:", zoomLevel1);

    const zoomVisibilityValues = this.getMinAndMaxZoom(zoomLevel);
    if (cachedData && zoomLevel == levels[levels.length - 1]) {
      return cachedData.map((data) => {
        return { ...data, ...zoomVisibilityValues };
      });
    }
    // Para cada location
    for (let i = 0; i < locations.length; i++) {
      const location1 = locations[i];
      if (locationsThatExistInAMerge.includes(location1.id)) {
        continue;
      }
      let mergedLocationArr = [location1];
      for (let j = i + 1; j < locations.length; j++) {
        const location2 = locations[j];
        if (
          locationsThatExistInAMerge.includes(location2.id) ||
          location1.bar.region !== location2.bar.region
        ) {
          continue;
        }

        const isOverlapping = this.arePointsOverlapping(
          location1,
          location2,
          chart
        );
        if (isOverlapping) {
          mergedLocationArr.push(location2);
        }
      }
      if (
        mergedLocationArr.length > 1 /* &&
         zoomLevel != levels[levels.length - 1] */
      ) {
        for (const location of mergedLocationArr) {
          locationsThatExistInAMerge.push(location.id);
        }
        const midpoint = this.getMidpoint(mergedLocationArr);
        const bulletColor = mergedLocationArr.some(
          (location) => location.cashback > 0
        )
          ? am5.color(cashbackColor)
          : am5.color(this.mergeBulletColor(mergedLocationArr.length));

        const mergeBulletRadius = this.getMergeBulletRadius(
          midpoint,
          mergedLocationArr,
          chart
        );

        const mergedLocation = {
          merged: true,
          id: mergedLocationArr.map((l) => l.id),
          quantity: mergedLocationArr.length,
          latitude: midpoint.latitude,
          longitude: midpoint.longitude,
          name: mergedLocationArr.map((l) => l.name),
          jackpot: mergedLocationArr.map((l) => l.jackpot),
          address: mergedLocationArr.map((l) => l.address),
          image: mergedLocationArr[0].image, // modify
          fill: mergedLocationArr[0].fill,
          bar: mergedLocationArr.map((l) => l.bar),
          bulletSettings: {
            fill: bulletColor,
            radius: mergeBulletRadius,
          },
          ...zoomVisibilityValues,
        };
        mergedLocations.push(mergedLocation);
      } else {
        mergedLocations.push({ ...location1, ...zoomVisibilityValues });
      }
    }
    return mergedLocations;
  },

  getAnimateOpacityObject(radius) {
    return {
      from: radius * 1.2,
      to: 0,
      loops: Infinity,
      duration: mapController.getRandomIntInclusive(1000, 2000),
      easing: am5.ease.yoyo(am5.ease.quad),
    };
  },

  getAnimateRadiusObject(radius) {
    return {
      key: "radius",
      to: radius * 1.6,
      loops: Infinity,
      duration: 2000,
      easing: am5.ease.yoyo(am5.ease.quad),
    };
  },

  getMergeBulletRadius(midpoint, locationsArray, chart) {
    const locationMidpoint = chart.convert({
      latitude: midpoint.latitude,
      longitude: midpoint.longitude,
    });
    // const zoomLevel = chart._settings.zoomLevel;
    let radius = 0;
    for (const location of locationsArray) {
      const point = chart.convert({
        latitude: location.latitude,
        longitude: location.longitude,
      });
      let distanceX = Math.abs(point.x - locationMidpoint.x);
      let distanceY = Math.abs(point.y - locationMidpoint.y);
      let distance = Math.hypot(distanceX, distanceY);
      if (distance > radius) {
        radius = distance;
      }
      //radius += addPixelRadiusPerBar * locationsArray.length * (zoomLevel / 10);
      /*       if (radius > maxCircleRadius) {
        return maxCircleRadius;
      } */
    }
    return radius;
  },

  getMidpoint(locations) {
    const totalLocations = locations.length;
    const toRadians = (value) => (value * Math.PI) / 180;
    const toDegrees = (value) => (value * 180) / Math.PI;

    // Calculate the midpoint
    let x = 0;
    let y = 0;
    let z = 0;

    locations.forEach((location) => {
      const lat = toRadians(location.latitude);
      const lon = toRadians(location.longitude);

      x += Math.cos(lat) * Math.cos(lon);
      y += Math.cos(lat) * Math.sin(lon);
      z += Math.sin(lat);
    });

    x /= totalLocations;
    y /= totalLocations;
    z /= totalLocations;

    const lon = Math.atan2(y, x);
    const hyp = Math.sqrt(x * x + y * y);
    const lat = Math.atan2(z, hyp);

    // Convert back to latitude and longitude
    const avgLat = toDegrees(lat);
    const avgLong = toDegrees(lon);

    // Return the midpoint as an object
    return {
      latitude: avgLat,
      longitude: avgLong,
    };
  },

  getArray(a, b) {
    let arr = [];
    if (Array.isArray(a)) {
      arr = a;
    } else {
      arr.push(a);
    }

    if (Array.isArray(b)) {
      arr = [...arr, ...b];
    } else {
      arr.push(b);
    }

    return arr;
  },
};
