<template>
  <div id="tops" :class="{ relative: minWidth560 }">
    <div
      class="container"
      :class="{ 'is-jackpots-open': jackpotVisible, relative: minWidth560 }"
    >
      <div class="flex h-full">
        <div
          class="flex flex-col justify-center flex-1 h-full xs:h-fit lg:h-full"
        >
          <!-- jackpots - arrow btn  -->
          <button
            id="btn-arrow"
            :class="{
              'is-jackpots-open': jackpotVisible || show == 'jackpots',
              'absolute -bottom-6 left-0 w-full bg-gray-900': minWidth560,
            }"
            @click="
              jackpotVisible = !jackpotVisible;
              cashbackVisible = false;
              show = 'jackpots';
            "
            v-show="!minWidth560 || show == 'jackpots'"
          >
            <svg
              :class="{
                'is-jackpots-open': jackpotVisible && show == 'jackpots',
              }"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
          <!-- jackpots - top jackpots btn -->
          <button
            id="btn-topjackpot"
            :class="{
              'is-jackpots-open': jackpotVisible || show == 'jackpots',
            }"
            @click="
              !minWidth560 ? (jackpotVisible = !jackpotVisible) : '';
              cashbackVisible = false;
              show = 'jackpots';
            "
          >
            <p class="xs:px-0.5">Top Jackpots</p>
          </button>
        </div>
        <div
          class="flex flex-col justify-center flex-1 h-full xs:h-fit lg:h-full"
        >
          <!-- cashbacks - arrow btn  -->
          <button
            id="btn-arrow"
            :class="{
              'is-cashback-open': cashbackVisible || show == 'cashbacks',
              'absolute -bottom-6 left-0 w-full bg-gray-900': minWidth560,
            }"
            @click="
              cashbackVisible = !cashbackVisible;
              jackpotVisible = false;
              show = 'cashbacks';
            "
            v-show="!minWidth560 || show == 'cashbacks'"
          >
            <svg
              :class="{
                'is-cashback-open': cashbackVisible && show == 'cashbacks',
              }"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
          <!-- cashbacks - top jackpots btn -->
          <button
            id="btn-topcashback"
            :class="{
              'is-cashback-open': cashbackVisible || show == 'cashbacks',
            }"
            @click="
              !minWidth560 ? (cashbackVisible = !cashbackVisible) : '';
              jackpotVisible = false;
              show = 'cashbacks';
            "
          >
            <p class="xs:px-0.5">Top Cashback</p>
          </button>
        </div>
      </div>
      <div
        id="jackpotsList"
        v-show="show == 'jackpots' && (jackpotVisible || minWidth560)"
        :class="{ 'is-jackpots-open': jackpotVisible }"
      >
        <router-link
          :to="{ name: 'Bar', params: { barName: bar.barName } }"
          class="w-full flex items-center text-md font-semibold uppercase justify-between mb-1 bg-gray-600 px-4 py-1 group"
          v-for="(bar, index) in filteredJackpots"
          :key="index"
        >
          <span class="xs:text-xs lg:text-xl"
            >{{ bar.visibleName }} ({{ bar.region }})</span
          >
          <span
            class="text-secondary text-xl xs:text-sm font-bold group-hover:text-white lg:text-xl"
            >{{ bar.value | money }}</span
          >
        </router-link>
      </div>
      <div
        id="cashbacksList"
        v-show="show == 'cashbacks' && (cashbackVisible || minWidth560)"
        :class="{ 'is-cashbacks-open': cashbackVisible }"
      >
        <router-link
          :to="{
            name: 'Bar',
            params: { barName: bar.barName },
          }"
          class="w-full flex items-center text-md font-semibold uppercase justify-between mb-1 bg-gray-600 px-4 py-1 group"
          v-for="(bar, index) in filteredCashbacks"
          :key="index"
        >
          <span class="xs:text-xs lg:text-xl"
            >{{ bar.visibleName }} ({{ bar.region }})</span
          >
          <span
            class="text-secondary text-xl xs:text-sm font-bold group-hover:text-white lg:text-xl"
            >{{ bar.value }} %</span
          >
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import minWidthMixin560 from "@/mixins/minWidthMixin560";
import jackpotsRepository from "@/api/jackpotsRepository";
export default {
  name: "TopJackpot",

  mixins: [minWidthMixin560],

  data() {
    return {
      jackpotVisible: false,
      topJackpots: null,
      tjHeight: null,
      cashbackVisible: false,
      topCashbacks: null,
      show: "jackpots",
    };
  },

  created() {
    jackpotsRepository
      .getTopJackpots()
      .then((response) => {
        this.topJackpots = response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    jackpotsRepository
      .getTopCashbacks()
      .then((response) => {
        this.topCashbacks = response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    this.startEventMW560();
  },

  mounted() {
    this.styleTP();
  },

  watch: {
    minWidth560: "styleTP",
  },

  computed: {
    filteredJackpots() {
      if (!this.topJackpots) {
        return [];
      }
      return this.jackpotVisible
        ? this.topJackpots
        : this.topJackpots.slice(0, 3);
    },

    filteredCashbacks() {
      if (!this.topCashbacks) {
        return [];
      }

      return this.cashbackVisible
        ? this.topCashbacks
        : this.topCashbacks.slice(0, 3);
    },
  },

  methods: {
    styleTP() {
      if (window.innerWidth < 560) {
        let tops = document.getElementById("tops");
        this.tjHeight = tops.offsetHeight + "px";
        document.getElementById("jackpotsList").style.marginBottom =
          this.tjHeight;
        document.getElementById("cashbacksList").style.marginBottom =
          this.tjHeight;
      } else {
        document.getElementById("jackpotsList").style.marginBottom = "0px";
        document.getElementById("cashbacksList").style.marginBottom = "0px";
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
#tops {
  height: 3rem;
  @apply flex items-center overflow-x-hidden;

  @screen xs {
    width: 40%;
    margin-left: 2%;
    margin-right: 5%;
    @apply h-full overflow-y-auto;
  }

  .container {
    @apply space-y-1 h-full flex flex-col-reverse justify-center bg-gray-900;
    @screen xs {
      background-image: none;
      height: fit-content;
      max-height: 75.8vh;
      @apply flex-col;
    }

    #btn-topjackpot,
    #btn-topcashback {
      /* height: 60%; */
      margin-top: -7px;
      color: white;
      transition-duration: 30ms;
      /* @apply w-full flex items-center justify-center uppercase font-bold tracking-wider text-lg; */
      /* font-size: 1.25rem; */
      @apply uppercase font-bold tracking-wider px-2 flex-1 /* bg-gradient-to-r from-primary to-blue-800 */ bg-gray-900 transition-all ease-in-out;
      @screen xs {
        height: 16%;
        margin-top: 0;
        @apply p-0 text-lg;
      }
      @screen lg {
        height: 10%;
        @apply text-2xl py-4;
      }

      &.is-jackpots-open,
      &.is-cashback-open {
        -webkit-background-clip: unset;
        -webkit-text-fill-color: white;
        @apply bg-gradient-to-r from-primary to-blue-800;
      }
    }

    #jackpotsList,
    #cashbacksList {
      /* margin-top: 1.5%; */
      @apply h-fit w-full absolute overflow-y-auto bg-gray-800 bottom-0;
      @screen xs {
        @apply static;
      }
      &.is-jackpots-open,
      &.is-cashback-open {
        @screen xs {
          max-height: 75%;
        }
      }
    }

    #btn-arrow {
      @apply mt-0 flex-1;
      @screen xs {
        height: fit-content;
      }

      @screen lg {
        @apply -bottom-12;
      }

      &.is-jackpots-open,
      &.is-cashback-open {
        @apply bg-gradient-to-r from-primary to-blue-800;

        svg {
          stroke: white;
        }
      }

      svg {
        stroke: white;
        @apply transform rotate-180 w-6 h-6 m-auto;
        @screen xs {
          @apply rotate-0;
        }
        @screen lg {
          @apply w-12 h-12;
        }

        &.is-jackpots-open,
        &.is-cashback-open {
          @apply transform rotate-180 -scale-y-1;
          @screen xs {
            @apply rotate-0;
          }
        }
      }
    }
  }
}
</style>
