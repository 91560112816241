var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { relative: _vm.minWidth560 }, attrs: { id: "tops" } },
    [
      _c(
        "div",
        {
          staticClass: "container",
          class: {
            "is-jackpots-open": _vm.jackpotVisible,
            relative: _vm.minWidth560,
          },
        },
        [
          _c("div", { staticClass: "flex h-full" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex flex-col justify-center flex-1 h-full xs:h-fit lg:h-full",
              },
              [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.minWidth560 || _vm.show == "jackpots",
                        expression: "!minWidth560 || show == 'jackpots'",
                      },
                    ],
                    class: {
                      "is-jackpots-open":
                        _vm.jackpotVisible || _vm.show == "jackpots",
                      "absolute -bottom-6 left-0 w-full bg-gray-900":
                        _vm.minWidth560,
                    },
                    attrs: { id: "btn-arrow" },
                    on: {
                      click: function ($event) {
                        _vm.jackpotVisible = !_vm.jackpotVisible
                        _vm.cashbackVisible = false
                        _vm.show = "jackpots"
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        class: {
                          "is-jackpots-open":
                            _vm.jackpotVisible && _vm.show == "jackpots",
                        },
                        attrs: { fill: "none", viewBox: "0 0 24 24" },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            d: "M19 9l-7 7-7-7",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _c(
                  "button",
                  {
                    class: {
                      "is-jackpots-open":
                        _vm.jackpotVisible || _vm.show == "jackpots",
                    },
                    attrs: { id: "btn-topjackpot" },
                    on: {
                      click: function ($event) {
                        !_vm.minWidth560
                          ? (_vm.jackpotVisible = !_vm.jackpotVisible)
                          : ""
                        _vm.cashbackVisible = false
                        _vm.show = "jackpots"
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "xs:px-0.5" }, [
                      _vm._v("Top Jackpots"),
                    ]),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "flex flex-col justify-center flex-1 h-full xs:h-fit lg:h-full",
              },
              [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.minWidth560 || _vm.show == "cashbacks",
                        expression: "!minWidth560 || show == 'cashbacks'",
                      },
                    ],
                    class: {
                      "is-cashback-open":
                        _vm.cashbackVisible || _vm.show == "cashbacks",
                      "absolute -bottom-6 left-0 w-full bg-gray-900":
                        _vm.minWidth560,
                    },
                    attrs: { id: "btn-arrow" },
                    on: {
                      click: function ($event) {
                        _vm.cashbackVisible = !_vm.cashbackVisible
                        _vm.jackpotVisible = false
                        _vm.show = "cashbacks"
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        class: {
                          "is-cashback-open":
                            _vm.cashbackVisible && _vm.show == "cashbacks",
                        },
                        attrs: { fill: "none", viewBox: "0 0 24 24" },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            d: "M19 9l-7 7-7-7",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _c(
                  "button",
                  {
                    class: {
                      "is-cashback-open":
                        _vm.cashbackVisible || _vm.show == "cashbacks",
                    },
                    attrs: { id: "btn-topcashback" },
                    on: {
                      click: function ($event) {
                        !_vm.minWidth560
                          ? (_vm.cashbackVisible = !_vm.cashbackVisible)
                          : ""
                        _vm.jackpotVisible = false
                        _vm.show = "cashbacks"
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "xs:px-0.5" }, [
                      _vm._v("Top Cashback"),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.show == "jackpots" &&
                    (_vm.jackpotVisible || _vm.minWidth560),
                  expression:
                    "show == 'jackpots' && (jackpotVisible || minWidth560)",
                },
              ],
              class: { "is-jackpots-open": _vm.jackpotVisible },
              attrs: { id: "jackpotsList" },
            },
            _vm._l(_vm.filteredJackpots, function (bar, index) {
              return _c(
                "router-link",
                {
                  key: index,
                  staticClass:
                    "w-full flex items-center text-md font-semibold uppercase justify-between mb-1 bg-gray-600 px-4 py-1 group",
                  attrs: {
                    to: { name: "Bar", params: { barName: bar.barName } },
                  },
                },
                [
                  _c("span", { staticClass: "xs:text-xs lg:text-xl" }, [
                    _vm._v(
                      _vm._s(bar.visibleName) + " (" + _vm._s(bar.region) + ")"
                    ),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass:
                        "text-secondary text-xl xs:text-sm font-bold group-hover:text-white lg:text-xl",
                    },
                    [_vm._v(_vm._s(_vm._f("money")(bar.value)))]
                  ),
                ]
              )
            }),
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.show == "cashbacks" &&
                    (_vm.cashbackVisible || _vm.minWidth560),
                  expression:
                    "show == 'cashbacks' && (cashbackVisible || minWidth560)",
                },
              ],
              class: { "is-cashbacks-open": _vm.cashbackVisible },
              attrs: { id: "cashbacksList" },
            },
            _vm._l(_vm.filteredCashbacks, function (bar, index) {
              return _c(
                "router-link",
                {
                  key: index,
                  staticClass:
                    "w-full flex items-center text-md font-semibold uppercase justify-between mb-1 bg-gray-600 px-4 py-1 group",
                  attrs: {
                    to: {
                      name: "Bar",
                      params: { barName: bar.barName },
                    },
                  },
                },
                [
                  _c("span", { staticClass: "xs:text-xs lg:text-xl" }, [
                    _vm._v(
                      _vm._s(bar.visibleName) + " (" + _vm._s(bar.region) + ")"
                    ),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass:
                        "text-secondary text-xl xs:text-sm font-bold group-hover:text-white lg:text-xl",
                    },
                    [_vm._v(_vm._s(bar.value) + " %")]
                  ),
                ]
              )
            }),
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }