import httpClient from "@/helpers/httpClient";

const basePath = "promotion";
const jackpotType = "jackpot";
const cashbackType = "cashback";

export default {
  getTopJackpots() {
    return httpClient().get(`${basePath}`, {
      params: { limit: 5, type: jackpotType },
    });
  },

  getTopCashbacks() {
    return httpClient().get(`${basePath}`, {
      params: { limit: 5, type: cashbackType },
    });
  },
};
