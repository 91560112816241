var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "hammer",
          rawName: "v-hammer:swipe.left.right",
          value: _vm.test,
          expression: "test",
          arg: "swipe",
          modifiers: { left: true, right: true },
        },
      ],
      staticClass: "slider-container relative px-5 w-full",
    },
    [
      _c("div", { staticClass: "slider-wrapper h-full w-full lg:h-1/2" }, [
        _c(
          "div",
          { staticClass: "slide h-full w-full lg:h-full" },
          [
            _c("Region", {
              key: _vm.selectedRegionIndex,
              attrs: {
                regionIndex: _vm.selectedRegionIndex,
                regionBars: _vm.regionBars,
                isRegionReady: _vm.isRegionReady,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass:
            "w-full h-full lg:w-full absolute top-0 left-0 flex items-center justify-between",
        },
        [
          _c(
            "button",
            {
              staticClass: "h-10 w-10 flex items-center absolute left-0",
              attrs: { id: "btn-prev" },
              on: {
                click: function ($event) {
                  return _vm.prevSlide()
                },
              },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "h-6 w-6 lg:h-full lg:w-auto xl:h-20 lg:w-20",
                  attrs: {
                    fill: "none",
                    viewBox: "0 0 24 24",
                    stroke: "#22d3ee",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      d: "M15 19l-7-7 7-7",
                    },
                  }),
                ]
              ),
            ]
          ),
          _c(
            "button",
            {
              staticClass:
                "h-10 w-10 flex items-center justify-end absolute right-0",
              attrs: { id: "btn-next" },
              on: {
                click: function ($event) {
                  return _vm.nextSlide()
                },
              },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "h-6 w-6 lg:h-full lg:w-auto xl:h-20 lg:w-20",
                  attrs: {
                    fill: "none",
                    viewBox: "0 0 24 24",
                    stroke: "#22d3ee",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      d: "M9 5l7 7-7 7",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }