<template>
  <div class="wrapper" @click="closeMenu">
    <SomethingWentWrong v-if="showWrong" />
    <Loader
      class="chartdiv"
      v-if="loading && !showWrong"
      style="z-index: 1"
      :style="hideTops ? 'width: 100% !important; height: 100%; top: 0;' : null"
    />
    <!-- SEARCH POP UP -->
    <div
      class="p-2 rounded-lg absolute top-2 left-2.5"
      style="z-index: 1"
      :class="{ 'bg-gray-900': screenSize !== 'desk' }"
      v-if="!openBarsList && !showSearchPopup"
    >
      <button
        @click="clickSearchPopup"
        class="flex items-center gap-x-2"
        :class="{ disabled: loading && !showWrong }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
        <span class="flex items-center" v-if="screenSize === 'desk'">
          <span
            class="px-2 m-1 border border-gray-400 bg-gray-900 rounded-lg text-sm"
          >
            CTRL
          </span>
          <span
            class="px-2 border border-gray-400 bg-gray-900 rounded-lg text-sm"
          >
            K
          </span>
        </span>
      </button>
    </div>
    <search-popup
      :rooms="bars ? bars : null"
      :showSearchPopup="showSearchPopup"
      :isMobile="!minWidth560"
      @closeSearchModal="closeSearchModalHandler"
      @updateShowSearchPopup="
        showSearchPopup = true;
        $emit('searchPopUpVisibility', true);
      "
    />
    <div id="btn-container" style="z-index: 1" v-if="showFullscreenIcon">
      <button
        id="exit-fullscreen-btn"
        v-if="isInFullscreen && !isStandalone"
        type="button"
        @click="emitToggle"
        class="bg-gray-900 border border-secondat p-2 rounded-lg"
        :class="{ disabled: loading && !showWrong }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          class="h-5 w-5"
          stroke="currentColor"
        >
          <!-- :style="isIpad ? 'margin-top: 0.45rem;' : ''" -->
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25"
          />
        </svg>
      </button>
      <button
        id="fullscreen-btn"
        v-if="!isInFullscreen && !isStandalone"
        type="button"
        @click="emitToggle"
        class="bg-gray-900 p-2 rounded-lg"
        :class="{ disabled: loading && !showWrong }"
      >
        <svg
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="1.5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
          />
        </svg>
      </button>
    </div>

    <!-- :class="hideTops ? 'alone' : 'half'" -->
    <div
      class="absolute top-3"
      style="z-index: 1"
      v-if="
        isMobileFirefox && chart && !selectedRegionIndex && !showSearchPopup
      "
    >
      <button
        class="rounded-l-lg border border-gray-800 w-8 py-0.5 bg-gray-500"
        @click.prevent="zoomOut"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="black"
          stroke-width="4"
          class="w-5 h-5 mx-auto"
        >
          <path
            fill-rule="evenodd"
            d="M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <button
        class="rounded-r-lg border border-gray-800 w-8 py-0.5 bg-gray-500"
        @click.prevent="zoomIn"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="black"
          stroke-width="4"
          class="w-5 h-5 mx-auto"
        >
          <path
            fill-rule="evenodd"
            d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
    <div v-if="!showWrong" class="chartdiv" id="chartdiv"></div>
    <TopJackpots v-if="!hideTops && !openBarsList" />
    <div
      class="w-full h-full bg-black bg-opacity-70 absolute top-0 left-0"
      v-if="openBarsList"
    >
      <div
        class="bars-container"
        :class="{ in: openingBarsList, out: closingBarsList }"
      >
        <CustomSwiper
          :totalRegions="totalRegions"
          :selectedRegionIndex="selectedRegionIndex"
          :regionBars="regionBars"
          :isRegionReady="isRegionReady"
          v-if="selectedRegion"
          @updateCurrent="handleUpdateCurrent"
          id="custom-swiper"
        >
          <!-- :key="selectedRegionIndex + '_' + openingBarsList" -->
        </CustomSwiper>
        <div
          class="flex justify-center flex-1 overflow-y-auto items-start xs:my-3 md:my-8"
        >
          <div id="bars-list">
            <div class="w-full flex items-start justify-between h-fit">
              <h1 id="region-title">
                {{ selectedRegion }}
              </h1>
              <button
                class="text-2xl px-2 -mr-1.5 lg:text-6xl"
                @click="closeRegion"
              >
                &times;
              </button>
            </div>
            <div
              v-if="regionBars.length > 0"
              class="relative flex-1 overflow-y-auto overflow-x-hidden h-full items-center"
            >
              <!-- style="padding-left: 6%; padding-right: 6%; padding-top: 4px" -->
              <router-link
                :to="{ name: 'Bar', params: { barName: bar.name } }"
                class="bar-link"
                v-for="(bar, index) in regionBars"
                :key="index"
              >
                <img
                  class="w-10 h-10 object-cover rounded-full lg:h-24 lg:w-24"
                  :src="
                    bar.image ? bar.image : '/pwa/images/icons/icon-192x192.png'
                  "
                  :alt="bar.visibleName"
                />
                <div class="flex justify-between flex-col flex-1">
                  <h2
                    class="uppercase font-bold ml-2 xs:text-lg xs:ml-0 lg:text-3xl truncate w-full"
                    :class="
                      bar.cashback > 0 ? 'text-primary' : 'text-secondary'
                    "
                  >
                    {{ bar.visibleName ? bar.visibleName : "-" }}
                  </h2>
                  <!-- <div class="w-1/4 flex flex-col items-center gap-1">
                  <p class="text-4xl font-bold">{{ bar.cabinetsFree }}/{{ bar.cabinets.length }}</p>
                  <p class="uppercase text-base font-semibold">Cabinets Disponibili</p>
                </div> -->
                  <!-- <div class="w-1/4 flex flex-col items-center gap-1">
                  <p class="text-4xl font-bold">{{ bar.numberGames }}</p>
                  <p class="uppercase text-base font-semibold">Giochi</p>
                </div> -->
                  <div id="bars-jackpots" v-if="!hideTops">
                    <p class="text-lg font-bold xs:text-xl lg:text-3xl">
                      {{ bar.jackpot ? bar.jackpot : 0 | money }}
                    </p>
                    <p
                      class="uppercase text-xxs font-semibold xs:text-xxs lg:text-base"
                      style="margin-top: -0.5rem"
                    >
                      Jackpot da Bar
                    </p>
                  </div>
                </div>
              </router-link>
            </div>
            <div v-else class="relative overflow-y-auto h-full">
              <p id="not-available">Nessuna Sala Disponibile.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import ClientBarPopup from "@/components/ClientBarPopup";
import Region from "@/components/Region";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import { getBars, getBarsByRegion } from "@/api/barsRepository";
import CustomSwiper from "@/components/CustomSwiper";
import Loader from "@/components/Loader";
import TopJackpots from "@/components/TopJackpots";
import italyMap from "@/helpers/italyMap";
import { mapController } from "@/helpers/mapController";
import iPhoneSetup from "@/mixins/iPhoneSetup";
import minWidthMixin560 from "@/mixins/minWidthMixin560";
import am5geodata_lang_IT from "@amcharts/amcharts5-geodata/lang/IT";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import _ from "lodash";
import SearchPopup from "./SearchPopup";
import SomethingWentWrong from "@/components/SomethingWentWrong";
import { mapState } from "vuex";

export default {
  name: "Glimpse",

  mixins: [minWidthMixin560, iPhoneSetup],

  components: {
    Region,
    TopJackpots,
    CustomSwiper,
    SearchPopup,
    Loader,
    SomethingWentWrong,
  },

  props: ["hideFullscreenBtn", "isInFullscreen"],

  data() {
    return {
      map: null,
      chart: null,
      openBarsList: false,
      selectedRegionIndex: null,
      openBar: null,
      barIndex: null,
      regionBars: [],
      homeButton: null,
      showSearchPopup: false,
      destroyRegion: false,
      loading: false,
      lastChartsZoomLevel: 1,
      lastChartsZoomLevelCalculated: 1,
      //temporary variable while store not ready to deal with bars
      bars: null,
      hideTops: process.env.VUE_APP_HIDE_JACKPOTS
        ? JSON.parse(process.env.VUE_APP_HIDE_JACKPOTS)
        : false,
      showWrong: false,
      screenSize: "mob",
      isMobileFirefox: false,
      openingBarsList: false,
      closingBarsList: false,
      isRegionReady: false,
    };
  },

  watch: {
    "chart._settings": {
      deep: true,
      async handler(val) {
        if (
          !this.loading &&
          val.zoomLevel !== this.lastChartsZoomLevel &&
          this.bars &&
          this.isNewInterval(val.zoomLevel)
        ) {
          await this.addPinsDebounce(val.zoomLevel);
          this.lastChartsZoomLevel = val.zoomLevel;
        }
      },
    },
  },

  computed: {
    ...mapState("deviceInfo", ["isStandalone"]),
    showFullscreenIcon() {
      return (
        !navigator.userAgent.match(/iphone|ipod/i) &&
        (navigator.userAgent.match(/android/i) || this.isIpad) &&
        !this.isStandalone &&
        this.$route.name === "SlotsBar" &&
        !this.hideFullscreenBtn
      );
    },
    selectedRegion() {
      if (this.selectedRegionIndex) {
        this.getBarsByRegion(
          this.map.geodata.features[this.selectedRegionIndex].properties.name
        );
        console.log(
          "SELECTED REGION ----",
          italyMap.features[this.selectedRegionIndex].properties.name
        );
        return italyMap.features[this.selectedRegionIndex].properties.name;
      }
    },
    totalRegions() {
      return this.map.geodata.features.length;
    },
    //store logic, dont delete below
    // bars() {
    //   return this.$store.getters["bars/getBars"];
    // },
  },

  async mounted() {
    this.isMobileFirefox =
      /Firefox/i.test(navigator.userAgent) && !this.minWidth560;
    //store logic below started, kinda
    // if (!this.$store.getters["bars/getBars"]) {
    // this.$store.dispatch("bars/getBars");
    // }

    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);

    this.loading = true;
    getBars()
      .then((res) => {
        this.bars = res.data;
      })
      .catch(() => {
        this.loading = false;
        this.showWrong = true;
      });

    // //am5.options.minPolylineStep = 2;
    //this.map = am5.create("chartdiv", am4maps.MapChart);
    this.map = am5.Root.new("chartdiv", {
      useSafeResolution: false,
    });

    /* REMOVED THE LOGO AS ASKED, I DONT KNOW WHAT CAN HAPPEN SINCE WE DONT PAY.........*/
    this.map._logo.dispose();

    // this.map.events.on("frameended", this.exportChart);

    this.map.setThemes([am5themes_Animated.new(this.map)]);

    this.map.geodataNames = am5geodata_lang_IT;
    this.map.geodata = italyMap;
    this.map.projection = new am5map.geoMercator();
    this.chart = this.map.container.children.push(
      am5map.MapChart.new(this.map, {
        visible: false,
        maxZoomLevel: 15,
        interactionsEnabled: false,
      })
    );

    let polygonSeries = this.chart.series.push(
      am5map.MapPolygonSeries.new(this.map, {
        geoJSON: italyMap,
        exclude: ["FR-H", "MT"], // "FR-H", "MT", "SM", "VA" these are the per region excluded
        fill: am5.color("#454545"),
        // NOT SURE if this is actually 'easing the map' ...
        interpolationDuration: 1000,
        interpolationEasing: am5.ease.inOut(am5.ease.linear),
        duration: 5000,
        easing: am5.ease.yoyo(am5.ease.quad),
        // NOT SURE if this is actually 'easing the map' .
        // draggable: false,
      })
    );
    polygonSeries.useGeodata = true;

    this.map.series = polygonSeries;

    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.setAll({
      stroke: am5.color("#dddddd"),
      strokeWidth: 0.5,
    });

    polygonTemplate.events.on("click", (ev, i) => {
      //   ev.target.series.chart.zoomToMapObject(ev.target)
      mapController.hideMapBullets();
      this.openRegion(ev.target.dataItem.dataContext.index);
    });

    if (this.bars) {
      await mapController.addPins(
        this.map,
        this.chart,
        Object.values(this.bars).flat(),
        true
      );

      this.loading = false;
      console.log("show map");
    } else {
      const unwatch = this.$watch("bars", (val) => {
        if (val) {
          this.addPins(true);
          unwatch();
        }
      });
    }

    document.addEventListener("visibilitychange", () => {
      //if(this.$refs.iframe && this.$refs.iframe.contentWindow) {
      if (document.visibilityState === "visible") {
        console.log("visible", new Date());
        mapController.displayMapBullets();
      } else {
        console.log("invisible", new Date());
        mapController.hideMapBullets(0);
      }
    });

    this.$store.dispatch("socket/emitChangeLocation", ["MAP"]);
  },
  methods: {
    closeSearchModalHandler() {
      this.showSearchPopup = false;
      this.$emit("searchPopUpVisibility", false);
    },

    clickSearchPopup() {
      this.showSearchPopup = !this.showSearchPopup;
      this.$emit("searchPopUpVisibility", this.showSearchPopup);
    },
    closeMenu() {
      this.$root.$emit("closeMenu");
    },
    emitToggle() {
      this.$root.$emit("toggled");
    },

    zoomIn() {
      this.chart.zoomIn();
    },

    zoomOut() {
      this.chart.zoomOut();
    },

    checkScreenSize() {
      if (window.innerWidth >= 1024) {
        this.screenSize = "desk";
      } else {
        this.screenSize = "mob";
      }
    },

    async addPins(init = false) {
      if (this.selectedRegionIndex == null) {
        await mapController.addPins(
          this.map,
          this.chart,
          Object.values(this.bars).flat(),
          init
        );
        console.log("loading false");
        this.chart.show(0);
        setTimeout(() => {
          const centerX = this.chart._centerLocation[0];
          const centerY = this.chart._centerLocation[1];
          const bounds = this.chart._geoBounds;
          const inBounds =
            centerX >= bounds.left &&
            centerX <= bounds.right &&
            centerY >= bounds.bottom &&
            centerY <= bounds.top;
          console.log(`is map broken ? `, !inBounds);
          if (!inBounds) {
            setTimeout(() => {
              this.chart.goHome(0);
              this.loading = false;
            }, 1000);
          } else {
            this.loading = false;
          }
        }, 0);
      }
    },

    isNewInterval(level) {
      const oldLevel = mapController.getMinAndMaxZoom(
        this.lastChartsZoomLevelCalculated
      );
      const newLevel = mapController.getMinAndMaxZoom(level);
      return (
        newLevel.zoomLevelToShow !== oldLevel.zoomLevelToShow ||
        newLevel.zoomLevelToHide !== oldLevel.zoomLevelToHide
      );
    },

    addPinsDebounce: _.debounce(function (zoomLevel) {
      // if it doesnt change to next interval - loader is not required
      if (this.selectedRegionIndex == null) {
        this.loading = true;
      }
      // this is done on propuse
      setTimeout(() => {
        this.addPins();
        this.lastChartsZoomLevelCalculated = zoomLevel;
      }, 0);
    }, 400),

    closeRegion() {
      this.closingBarsList = true;
      // document.getElementById("soundButton").play();
      // this.$emit("regionPopUpVisibility", false);
      this.$emit("fullscreenBtnVisibility", true);
      mapController.showMapBullets(
        this.map,
        this.chart,
        Object.values(this.bars).flat()
      );
      this.chart.goHome();
      setTimeout(() => {
        this.closingBarsList = false;
        this.selectedRegionIndex = null;
        this.openBarsList = false;
        this.isRegionReady = false;
      }, 100);
    },

    getBarsByRegion(selectedRegion) {
      getBarsByRegion(selectedRegion)
        .then((response) => {
          console.log("got bars by regions", response.data);
          this.regionBars = response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    openRegion(regionIndex) {
      this.openingBarsList = true;
      this.openBarsList = true;
      // document.getElementById("soundButton").play();
      this.showSearchPopup = false;
      this.$emit("searchPopUpVisibility", false);
      this.$emit("fullscreenBtnVisibility", false);
      // this.$emit("regionPopUpVisibility", true);
      this.selectedRegionIndex = regionIndex;

      setTimeout(() => {
        this.openingBarsList = false;
        this.isRegionReady = true;
      }, 100);
    },

    openBarPopup(bar, region) {
      this.showSearchPopup = false;
      this.$emit("searchPopUpVisibility", false);
      this.openBar = bar;
      this.barIndex = parseInt(
        Object.keys(this.$root.$data.barsPerRegion[region]).find(
          (key) =>
            this.$root.$data.barsPerRegion[region][key].name ===
            this.openBar.name
        )
      );
      // this.$router.push(`/bar/${bar.name}`);
    },

    handleUpdateCurrent(newCurrent) {
      this.selectedRegionIndex = newCurrent;
      this.closeMenu();
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.map.dispose();
    }
  },
};
</script>

<style scoped lang="postcss">
.wrapper {
  touch-action: none; /* fullscreen iOS fix, prevent exiting accidentally */
  @apply relative h-full flex flex-col items-center justify-center;
  @screen xs {
    @apply flex-row;
  }
  .chartdiv {
    width: 100%;
    @apply flex-1;
    @screen xs {
      width: 50%;
      @apply h-full;
    }
  }

  #btn-container {
    top: 0.5rem;
    z-index: 1;
    @apply absolute right-2.5;
  }
}

.in {
  animation: openModal 0.1s ease-in forwards;
}

.out {
  animation: closeModal 0.1s ease-out forwards;
}

.bars-container {
  @apply w-full h-full px-6 flex flex-col gap-y-2;

  @screen xs {
    /* padding-top: 2%; */
    padding-bottom: 0;
    @apply flex-row px-3;
  }

  #custom-swiper {
    margin-top: 5%;
    @apply h-1/4 w-full;
    @screen xs {
      margin-left: 3%;
      @apply w-2/5 h-full flex items-center mt-0;
    }
  }

  #bars-list {
    /* padding-top: 20%;
      min-width: 90%; */
    @apply h-full w-full flex flex-col;
    @screen xs {
      @apply w-5/6;
    }
    @screen md {
      /* height: fit-content; */
      height: 90%;
      /* @apply overflow-y-auto; */
    }
    @screen lg {
      padding-top: 5%;
    }
    #region-title {
      @apply flex-1 font-bold text-xl text-primary uppercase pl-0.5;
      /*@screen xs {
        padding-left: 9%;
      }*/
      @screen lg {
        @apply text-5xl;
      }
    }
    #bars-jackpots {
      @apply flex flex-col items-center gap-1;
      @screen xs {
        margin-top: -0.2rem;
      }
    }
    .bar-link {
      transition-duration: 30ms;
      /* justify-center */
      background: #050505;
      @apply flex items-center gap-x-4 text-white py-2 px-3 rounded-lg mb-2.5 transform border border-gray-800;
      &:hover {
        box-shadow: inset 0 0 8px #f4b10a;
      }
      @screen xs {
        @apply py-2;
      }

      @screen md {
        @apply py-3;
      }
    }
  }

  #not-available {
    @screen xs {
      padding-left: 9%;
    }
  }
}
</style>
