var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper", on: { click: _vm.closeMenu } },
    [
      _vm.showWrong ? _c("SomethingWentWrong") : _vm._e(),
      _vm.loading && !_vm.showWrong
        ? _c("Loader", {
            staticClass: "chartdiv",
            staticStyle: { "z-index": "1" },
            style: _vm.hideTops
              ? "width: 100% !important; height: 100%; top: 0;"
              : null,
          })
        : _vm._e(),
      !_vm.openBarsList && !_vm.showSearchPopup
        ? _c(
            "div",
            {
              staticClass: "p-2 rounded-lg absolute top-2 left-2.5",
              class: { "bg-gray-900": _vm.screenSize !== "desk" },
              staticStyle: { "z-index": "1" },
            },
            [
              _c(
                "button",
                {
                  staticClass: "flex items-center gap-x-2",
                  class: { disabled: _vm.loading && !_vm.showWrong },
                  on: { click: _vm.clickSearchPopup },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "w-5 h-5",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        fill: "none",
                        viewBox: "0 0 24 24",
                        "stroke-width": "1.5",
                        stroke: "currentColor",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          d: "M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z",
                        },
                      }),
                    ]
                  ),
                  _vm.screenSize === "desk"
                    ? _c("span", { staticClass: "flex items-center" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "px-2 m-1 border border-gray-400 bg-gray-900 rounded-lg text-sm",
                          },
                          [_vm._v(" CTRL ")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "px-2 border border-gray-400 bg-gray-900 rounded-lg text-sm",
                          },
                          [_vm._v(" K ")]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c("search-popup", {
        attrs: {
          rooms: _vm.bars ? _vm.bars : null,
          showSearchPopup: _vm.showSearchPopup,
          isMobile: !_vm.minWidth560,
        },
        on: {
          closeSearchModal: _vm.closeSearchModalHandler,
          updateShowSearchPopup: function ($event) {
            _vm.showSearchPopup = true
            _vm.$emit("searchPopUpVisibility", true)
          },
        },
      }),
      _vm.showFullscreenIcon
        ? _c(
            "div",
            { staticStyle: { "z-index": "1" }, attrs: { id: "btn-container" } },
            [
              _vm.isInFullscreen && !_vm.isStandalone
                ? _c(
                    "button",
                    {
                      staticClass:
                        "bg-gray-900 border border-secondat p-2 rounded-lg",
                      class: { disabled: _vm.loading && !_vm.showWrong },
                      attrs: { id: "exit-fullscreen-btn", type: "button" },
                      on: { click: _vm.emitToggle },
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "h-5 w-5",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            fill: "none",
                            viewBox: "0 0 24 24",
                            "stroke-width": "1.5",
                            stroke: "currentColor",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              d: "M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25",
                            },
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.isInFullscreen && !_vm.isStandalone
                ? _c(
                    "button",
                    {
                      staticClass: "bg-gray-900 p-2 rounded-lg",
                      class: { disabled: _vm.loading && !_vm.showWrong },
                      attrs: { id: "fullscreen-btn", type: "button" },
                      on: { click: _vm.emitToggle },
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "h-5 w-5",
                          attrs: {
                            fill: "none",
                            viewBox: "0 0 24 24",
                            stroke: "currentColor",
                            "stroke-width": "1.5",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              d: "M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15",
                            },
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm.isMobileFirefox &&
      _vm.chart &&
      !_vm.selectedRegionIndex &&
      !_vm.showSearchPopup
        ? _c(
            "div",
            { staticClass: "absolute top-3", staticStyle: { "z-index": "1" } },
            [
              _c(
                "button",
                {
                  staticClass:
                    "rounded-l-lg border border-gray-800 w-8 py-0.5 bg-gray-500",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.zoomOut.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "w-5 h-5 mx-auto",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 24 24",
                        fill: "black",
                        "stroke-width": "4",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          d: "M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z",
                          "clip-rule": "evenodd",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "rounded-r-lg border border-gray-800 w-8 py-0.5 bg-gray-500",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.zoomIn.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "w-5 h-5 mx-auto",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 24 24",
                        fill: "black",
                        "stroke-width": "4",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          d: "M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z",
                          "clip-rule": "evenodd",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      !_vm.showWrong
        ? _c("div", { staticClass: "chartdiv", attrs: { id: "chartdiv" } })
        : _vm._e(),
      !_vm.hideTops && !_vm.openBarsList ? _c("TopJackpots") : _vm._e(),
      _vm.openBarsList
        ? _c(
            "div",
            {
              staticClass:
                "w-full h-full bg-black bg-opacity-70 absolute top-0 left-0",
            },
            [
              _c(
                "div",
                {
                  staticClass: "bars-container",
                  class: { in: _vm.openingBarsList, out: _vm.closingBarsList },
                },
                [
                  _vm.selectedRegion
                    ? _c("CustomSwiper", {
                        attrs: {
                          totalRegions: _vm.totalRegions,
                          selectedRegionIndex: _vm.selectedRegionIndex,
                          regionBars: _vm.regionBars,
                          isRegionReady: _vm.isRegionReady,
                          id: "custom-swiper",
                        },
                        on: { updateCurrent: _vm.handleUpdateCurrent },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex justify-center flex-1 overflow-y-auto items-start xs:my-3 md:my-8",
                    },
                    [
                      _c("div", { attrs: { id: "bars-list" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-full flex items-start justify-between h-fit",
                          },
                          [
                            _c("h1", { attrs: { id: "region-title" } }, [
                              _vm._v(" " + _vm._s(_vm.selectedRegion) + " "),
                            ]),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "text-2xl px-2 -mr-1.5 lg:text-6xl",
                                on: { click: _vm.closeRegion },
                              },
                              [_vm._v(" × ")]
                            ),
                          ]
                        ),
                        _vm.regionBars.length > 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "relative flex-1 overflow-y-auto overflow-x-hidden h-full items-center",
                              },
                              _vm._l(_vm.regionBars, function (bar, index) {
                                return _c(
                                  "router-link",
                                  {
                                    key: index,
                                    staticClass: "bar-link",
                                    attrs: {
                                      to: {
                                        name: "Bar",
                                        params: { barName: bar.name },
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass:
                                        "w-10 h-10 object-cover rounded-full lg:h-24 lg:w-24",
                                      attrs: {
                                        src: bar.image
                                          ? bar.image
                                          : "/pwa/images/icons/icon-192x192.png",
                                        alt: bar.visibleName,
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex justify-between flex-col flex-1",
                                      },
                                      [
                                        _c(
                                          "h2",
                                          {
                                            staticClass:
                                              "uppercase font-bold ml-2 xs:text-lg xs:ml-0 lg:text-3xl truncate w-full",
                                            class:
                                              bar.cashback > 0
                                                ? "text-primary"
                                                : "text-secondary",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  bar.visibleName
                                                    ? bar.visibleName
                                                    : "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        !_vm.hideTops
                                          ? _c(
                                              "div",
                                              {
                                                attrs: { id: "bars-jackpots" },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-lg font-bold xs:text-xl lg:text-3xl",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("money")(
                                                            bar.jackpot
                                                              ? bar.jackpot
                                                              : 0
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "uppercase text-xxs font-semibold xs:text-xxs lg:text-base",
                                                    staticStyle: {
                                                      "margin-top": "-0.5rem",
                                                    },
                                                  },
                                                  [_vm._v(" Jackpot da Bar ")]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              1
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "relative overflow-y-auto h-full",
                              },
                              [
                                _c("p", { attrs: { id: "not-available" } }, [
                                  _vm._v("Nessuna Sala Disponibile."),
                                ]),
                              ]
                            ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }