<template>
  <div class="loader-container">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style lang="postcss" scoped>
.loader-container {
  background-color: rgba(34, 34, 34, 0.5);
  height: calc(100% - 3rem);
  @apply absolute flex items-center justify-center z-10;

  @screen sm {
    @apply h-full;
  }
}

.loader {
  border: 12px solid #f3f3f3; /* Light grey */
  border-top: 12px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
