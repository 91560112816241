var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full h-full flex flex-col justify-center items-center" },
    [
      _c(
        "svg",
        {
          staticClass: "h-20 w-20",
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            fill: "none",
            viewBox: "0 0 24 24",
            stroke: "currentColor",
          },
        },
        [
          _c("path", {
            attrs: {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "1.5",
              d: "M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
            },
          }),
        ]
      ),
      _c("h1", { staticClass: "uppercase font-semibold text-3xl" }, [
        _vm._v("Qualcosa è andato storto"),
      ]),
      _c("h2", [_vm._v("Si prega di provare ad aggiornare.")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }