<template>
  <div
    class="slider-container relative px-5 w-full"
    v-hammer:swipe.left.right="test"
  >
    <div class="slider-wrapper h-full w-full lg:h-1/2">
      <div class="slide h-full w-full lg:h-full">
        <Region
          :regionIndex="selectedRegionIndex"
          :key="selectedRegionIndex"
          :regionBars="regionBars"
          :isRegionReady="isRegionReady"
        />
      </div>
    </div>
    <div
      class="w-full h-full lg:w-full absolute top-0 left-0 flex items-center justify-between"
    >
      <button
        id="btn-prev"
        class="h-10 w-10 flex items-center absolute left-0"
        @click="prevSlide()"
      >
        <svg
          class="h-6 w-6 lg:h-full lg:w-auto xl:h-20 lg:w-20"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#22d3ee"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <button
        id="btn-next"
        class="h-10 w-10 flex items-center justify-end absolute right-0"
        @click="nextSlide()"
      >
        <svg
          class="h-6 w-6 lg:h-full lg:w-auto xl:h-20 lg:w-20"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#22d3ee"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import Region from "@/components/Region";

export default {
  props: ["totalRegions", "selectedRegionIndex", "regionBars", "isRegionReady"],

  components: { Region },

  mounted() {
    console.log("CUSTOM SWIPER :: Mounted hook called");

    window.addEventListener("keydown", this.keydownHandler);
  },

  methods: {
    keydownHandler(e) {
      e.preventDefault();
      if (e.keyCode == "37") {
        // left arrow
        this.prevSlide();
      } else if (e.keyCode == "39") {
        // right arrow
        this.nextSlide();
      }
    },
    closeMenu() {
      this.$root.$emit("closeMenu");
    },
    prevSlide() {
      // 4 to exclude Corse, Malta, Vaticano e San Marino
      if (this.selectedRegionIndex === 4) {
        this.$emit("updateCurrent", this.totalRegions - 1);
      } else {
        this.$emit("updateCurrent", this.selectedRegionIndex - 1);
      }
    },

    nextSlide() {
      if (this.selectedRegionIndex === this.totalRegions - 1) {
        // 4 to exclude Corse, Malta, Vaticano e San Marino
        this.$emit("updateCurrent", 4);
      } else {
        this.$emit("updateCurrent", this.selectedRegionIndex + 1);
      }
    },

    test(swipe) {
      if (swipe.type === "swipeleft") {
        this.nextSlide();
      } else {
        this.prevSlide();
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.keydownHandler);
  },
};
</script>

<style lang="postcss" scoped>
.swiper-container {
  width: 100%;
  height: 100px;
}
#btn-prev,
#btn-next {
  top: 30%;
  @screen xs {
    top: 46%;
  }
}
</style>
